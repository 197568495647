var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "SystemMatter" },
    _vm._l(_vm.systemMatterList, function (matter) {
      return _c("div", { key: matter.id }, [
        matter.list.length
          ? _c("div", { staticClass: "matter flex-align-center" }, [
              _c("span", { staticClass: "matter-time" }, [_vm._v("全天")]),
              _c("div", { staticClass: "matter-content" }, [
                _c("div", { staticClass: "matter-title flex-align-center" }, [
                  _c("span", {
                    staticClass: "type-color",
                    class: matter.matterType ? "success" : "warning",
                  }),
                  _c("span", [_vm._v("今日" + _vm._s(matter.label))]),
                ]),
                _c(
                  "div",
                  { staticClass: "matter-text" },
                  [
                    _vm._l(matter.list, function (user, i) {
                      return _c("span", { key: i }, [
                        i < 3
                          ? _c("span", [
                              _vm._v(_vm._s(user.staffName) + " "),
                              matter.type === "overtime"
                                ? _c("span", { staticClass: "num" }, [
                                    _vm._v(_vm._s(user.overtimeHours) + "小时"),
                                  ])
                                : matter.type === "absent"
                                ? _c("span", { staticClass: "num" }, [
                                    _vm._v(_vm._s(user.absentHours) + "小时"),
                                  ])
                                : matter.type === "late"
                                ? _c("span", { staticClass: "num" }, [
                                    _vm._v(_vm._s(user.lateinMinutes) + "分钟"),
                                  ])
                                : matter.type === "early"
                                ? _c("span", { staticClass: "num" }, [
                                    _vm._v(
                                      _vm._s(user.earlyoutMinutes) + "分钟"
                                    ),
                                  ])
                                : matter.type === "miss"
                                ? _c("span", { staticClass: "num" }, [
                                    _vm._v(_vm._s(user.missTimes) + "次"),
                                  ])
                                : _vm._e(),
                              (matter.list.length > 2 && i !== 2) ||
                              (matter.list.length < 3 &&
                                i !== matter.list.length - 1)
                                ? _c("span", { staticClass: "num" }, [
                                    _vm._v("、"),
                                  ])
                                : _vm._e(),
                            ])
                          : _vm._e(),
                      ])
                    }),
                    matter.list.length > 3
                      ? _c("span", [
                          _vm._v("等" + _vm._s(matter.list.length - 3) + "人"),
                        ])
                      : _vm._e(),
                  ],
                  2
                ),
              ]),
            ])
          : _vm._e(),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }